.mapRoot h4 {
  padding: 20px 0 10px;
  margin: 0px;
  font-size: 14px !important;
  color: #333;
  font-weight: 500;
}

.mapRoot span {
  padding: 0px;
  margin: 0px;
  font-size: 14px !important;
  color: #333;
  font-weight: 500;
  text-decoration: dotted;
}

.mapRoot h4 {
  font-weight: bold;
}

.calendar_padding {
  width: 40px;
  height: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_padding:nth-child(1) {
  color: #ea6e6e;
}

.selected-max {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ea6e6e;
  color: #fff;
  font-weight: 500;
}

.swiper-button-prev {
  color: #333;
  opacity: 0.7;
}

.swiper-button-next {
  color: #333;
  opacity: 0.7;
}
