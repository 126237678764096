.white {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css?family=Baloo+2|Nanum+Gothic|Noto+Sans+JP|Noto+Serif+KR&display=swap");
.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

h4 {
  color: #333;
}

.calendar-premium {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5em;
  margin-top: 2em;
  color: #333;
  line-height: 2em;
  padding-bottom: 0.5em;
}

.calendar-month-premium {
  font-size: 2em;
  padding-top: 0.5em;
  margin-bottom: 0.5em;
  display: inline-block;
}

.calendar-division-premium {
  display: inline-block;
  width: 14.28%;
}

.calendar-day-premium {
  font-size: 0.6rem;
}

.calendar-day-premium:nth-child(1) {
  color: red;
}

.calendar-num-premium {
  font-size: 0.8rem;
}

.calendar-num-premium:nth-child(1) {
  color: red;
}

.calendar-height-premium {
  height: 2.5em;
  display: flex;
  align-items: center;
}

.selected-premium {
  background-color: #333;
  color: white;
  border-radius: 2em;
  width: 2.5em;
  display: inline-block;
}

.grayed-premium {
  display: none;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
