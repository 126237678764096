.custom-input {
  height: auto;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.custom-input > label {
  display: inline-block;
  width: 200px;
}

.custom-input > input {
  height: 40px;
  margin-left: 30px;
  width: 300px;
}

.custom-input > textarea {
  margin-left: 30px;
  width: 300px;
  height: 500px;
}
