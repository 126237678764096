.brown {
  text-align: center;
  font-family: "Baloo 2", cursive;
}

@import url("https://fonts.googleapis.com/css?family=Baloo+2|Nanum+Gothic|Noto+Sans+JP|Noto+Serif+KR&display=swap");
h4 {
  margin: 0 !important;
  font-size: 1.2em;
  padding-top: 2em;
  padding-bottom: 0.5em;
  color: #49301e;
}

.MainName {
  font-family: "Noto Serif KR", serif;
  color: #49301e;
}

.subText {
  font-family: "Noto Serif KR", sans-serif;
  color: #49301e;
  font-size: 13px;
  padding: 30px;
  line-height: 30px;
}

.calendar {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 5em;
  margin-top: 2em;
  color: #49301e;
  line-height: 2em;
  padding-bottom: 0.5em;
}

.calendar-month {
  font-size: 2em;
  padding-top: 0.5em;
  margin-bottom: 0.5em;
  display: inline-block;
}

.calendar-division {
  display: inline-block;
  width: 14.28%;
}

.calendar-day {
  font-size: 0.8rem;
}
.calendar-num {
  font-size: 1.2rem;
}

.calendar-height {
  height: 2.5em;
  display: flex;
  align-items: center;
}

.selected {
  background-color: #49301e;
  color: white;
  border-radius: 2em;
  width: 1.7em;
  display: inline-block;
}

.grayed {
  display: none;
}

.hello {
  /* border-bottom: 1.5px solid #49301e; */
  padding-bottom: 6em;
  font-size: 0.8em;
  line-height: 1.3em;
  width: 100%;
  margin: 0 auto;
  color: #49301e;
  margin-top: 2em;
}

.location-text {
  width: 75%;
  text-align: left;
  margin: 0 auto;
  padding-bottom: 5em;
  font-size: 0.8em;
  line-height: 1.2em;
  padding-top: 1em;
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.swiper-pagination-bullet-active {
  background: #333 !important;
}

.brownWeddingHow {
  width: 75%;
  text-align: left;
  margin: 0 auto;
  padding-bottom: 5em;
  font-size: 0.8em;
  line-height: 1.2em;
  padding-top: 1em;
}
.brownWeddingHow h4 {
  color: #49301e !important;
}

/* 벚꽃효과 */
.snow-bg {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-image: url(/src/assets/images/sakura-1.png),
    url(/src/assets/images/sakura-2.png);
  animation: snow 10s linear infinite;
}

@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 1000px 1000px, 200px 400px, -200px 300px;
  }
}

@media (max-width: 768px) {
  .woocommerce ul.products li.product,
  .woocommerce-page ul.products li.product,
  .woocommerce-page[class*="columns-"] ul.products li.product,
  .woocommerce[class*="columns-"] ul.products li.product {
    width: 100%;
    float: left;
    clear: both;
    margin: 0 7em;
  }
}
/* 벚꽃효과 */
